import React, {useState} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { IntlProvider } from "react-intl";
import loadable from '@loadable/component';
import 'react-toastify/dist/ReactToastify.css';

import intlMessages_en from './i18n/locales/en.json';
import intlMessages_es from './i18n/locales/es.json';
import intlMessages_pt from './i18n/locales/pt.json';
import intlMessages_fr from './i18n/locales/fr.json';
import intlMessages_it from './i18n/locales/it.json';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/fr';
import '@formatjs/intl-pluralrules/locale-data/es';
import '@formatjs/intl-pluralrules/locale-data/pt';
import '@formatjs/intl-pluralrules/locale-data/it';

import './App.css';

const Layout = loadable(() => import('./template/Layout'));
const Home = loadable(() => import('./page/HomePage'));
const Dashboard = loadable(() => import('./api/dashboard/Dashboard'));
const Articles = loadable(() => import ('./api/article/Articles'));
const Members = loadable(() => import('./api/member/Members'));
const Stories = loadable(() => import('./api/story/Stories'));
const Translations = loadable(() => import('./api/translation/Translations'));
const Faqs = loadable(() => import('./api/faq/Faqs'));
const Organisations = loadable(() => import('./api/organisation/Organisations'));
const Propositions = loadable(() => import('./api/proposition/Propositions'));
const Projects = loadable(() => import('./api/project/Projects'));
const Project = loadable(() => import('./api/project/Project'));
const Services = loadable(() => import('./api/service/Services'));
const Uniques = loadable(() => import('./api/unique/Uniques'));

const messages = {
  'en': intlMessages_en,
  'es': intlMessages_es,
  'pt': intlMessages_pt,
  'fr': intlMessages_fr,
  'it': intlMessages_it,
};

const App = () => {
  let navLocale =  (navigator.languages && navigator.languages[0])
                 || navigator.language
                 || navigator.userLanguage
                 || 'en';
  navLocale = navLocale.split("-")[0];
  const [locale, setLocale] = useState(navLocale);
  const switchLang = locale => setLocale(locale);

  return (
    <IntlProvider key={locale} locale={locale} messages={messages[locale]}>
          <Router>
                <Route render={({ location, history }) => {
                    return (
                      <Layout locale={locale} switchLang={switchLang} history={history}>
                        <Switch location={location} history={history}>
                          <Route exact path="/"> <Home history={history} /> </Route>
                          <Route exact path="/Dashboard"><Dashboard history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Articles"><Articles history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Members"><Members history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Stories"><Stories history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Translations"><Translations history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Faqs"><Faqs history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Propositions"><Propositions history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Projects"><Projects history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Project/:id"><Project  history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Organisations"><Organisations history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Services"><Services history={history} locale={locale} switchLang={switchLang}/></Route>
                          <Route exact path="/Uniques"><Uniques history={history} locale={locale} switchLang={switchLang}/></Route>
                        </Switch>
                      </Layout>
                    );
                  }}/>
          </Router>
    </IntlProvider>
  );
}

export default App;
